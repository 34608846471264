const CloseWhite = (props) => {
  	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		  <path d="M22 2L12 12L2 2" stroke="#FFEA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
		  <path d="M2 22L12 12L22 22" stroke="#FFEA00" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
  	);
};

export default CloseWhite;
