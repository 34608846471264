import styles from "./FindYourHive.module.css";
import React, { useState, useEffect, useRef, } from 'react';  
import Helper from './Helper';
import parse from 'html-react-parser';
import { DownArrow, RightBigArrow, CloseBlack, } from '../icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FindYourHive = (props) => {
  const myContainer = useRef(null);
  const [selectedVibes, setSelectedVibes] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const { height, width } = Helper.getWindowDimensions();
  const [currentStep, setCurrentStep] = useState(1);
  const [isTourOpen, setIsTourOpen] = useState(true);
  const [formState, setFormState] = useState({
    phone: '',
    name: '',
    email: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);

  setTimeout(
    () => {
      if (isTourOpen) setIsTourOpen(false)
    },
    10000
  );

  const onClickChoice = (e, que, type, val, selectionCap=0) => {
    formState[que] = formState[que] || [];

    if (type === 'choice-single') {
      if (formState[que][0] === val) {
        formState[que][0] = '';
        Helper.trackAnalytics(`FindYourHive`, 'Click_Remove_choice', val);
      } else {
        formState[que][0] = val;
        Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
      }
    } else {
      if (formState[que].includes(val)) {
        formState[que] = formState[que].filter(av => av !== val);
        Helper.trackAnalytics(`FindYourHive`, 'Click_Remove_choice', val);
      } else {
        if (selectionCap > 0) {
          if (formState[que].length < selectionCap) {
            formState[que].push(val);
            Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
          }
        } else {
          formState[que].push(val);
          Helper.trackAnalytics(`FindYourHive`, 'Click_Add_choice', val);
        }
      }
    }

    setFormState({...formState});
  };

  const questionnaires = [
    {
      "question": "Who do you vibe best with?",
      "type": "choice-single",
      "choices": [
        "Solo wanderers and free spirits",
        "Chill small groups (3-5 people)",
        "Larger squads (6+ people) for maximum fun!",
        "I’m cool with anything as long as we vibe!"
      ]
    },
    {
      "question": "What’s your preferred trip duration?",
      "type": "choice-single",
      "choices": [
        "1-2 days",
        "3-5 days",
        "6+ days",
        "Surprise me!"
      ]
    },
    {
      "question": "Do you have any specific dates or a timeframe in mind for this trip?",
      "type": "choice-single",
      "choices": [
        "I’m ready to go ASAP—let's make it happen!",
        "January - Let’s kickstart the year with epic adventures!",
        "February sounds better—let’s plan for that!",
        "March: Perfect weather, perfect timing—let’s explore then!",
        "April to June: Spring blooms, summer vibes—an adventure-filled season awaits!",
        "I’m Flexible: If it’s exciting, I’m game—no set dates needed."
      ]
    },
    {
      "question": "What’s your ideal travel style when it comes to comfort?",
      "type": "choice-single",
      "choices": [
        "Budget-Friendly & Raw: Hostels, campfires, and local eats.",
        "Balanced & Chill: Cozy stays, fun experiences, and reasonable indulgence.",
        "Luxury Explorer: Boutique hotels, fine dining, and high-end experiences.",
        "Flexible: The experience matters more than the spend."
      ]
    },
    {
      "question": "What kind of explorer are you? Share your travel mantra!",
      "type": "textarea",
      "placeholder": "I love exploring offbeat paths and meeting new people.",
      "choices": []
    },
    {
      "question": "Any special requests, wild ideas, or secret travel dreams? Share them here!",
      "type": "textarea",
      "placeholder": "I’d love to join a group that hikes to remote temples.",
      "choices": []
    },
    {
      "question": "Where are you currently located?",
      "type": "text",
      "placeholder": "Location",
      "choices": []
    },
  ];

  const onChangeInput = (e, type) => {
    e.preventDefault();

    if (type === 'phone') {
      formErrorState['phone'] = false;
      setFormErrorState({...formErrorState});
      if (e.target.value.length < 11) {
        formState['phone'] = e.target.value;
        setFormState({...formState});
      }
    } else if (type === 'name') {
      formErrorState.name = false;
      setFormErrorState({...formErrorState});
      const result = e.target.value.replace(/[^a-z ]/gi, '');
      formState.name = result;
      setFormState({...formState});
    } else if (type === 'email') {
      formErrorState.email = false;
      setFormErrorState({...formErrorState});
      formState.email = e.target.value;
      setFormState({...formState});
    } else {
      formErrorState[type] = false;
      setFormErrorState({...formErrorState});
      formState[type] = e.target.value;
      setFormState({...formState});
    }
  };

  const getQuestionnaires = () => {
    return questionnaires.map((inputQue, i) => {
      return(
        (inputQue.type === 'choice-single' || inputQue.type === 'choice-multi') ? 
          <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            {inputQue.choices.map((choice, j) => {
                return(<span key={j} onClick={e => onClickChoice(e, inputQue.question, inputQue.type, choice)} className={(formState[inputQue.question] || []).includes(choice) ? styles.choiceSelected : styles.choice}>{parse(choice)}</span>)
            })}
          </div>
        : (inputQue.type === 'textarea' ?
          <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            <textarea className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} placeholder={inputQue.placeholder} value={formState[inputQue.question]} rows="2" onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
          : <div key={i}>
            <label className={styles.label}>{inputQue.question}</label>
            <input className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} type={inputQue.type} placeholder={inputQue.placeholder} value={formState[inputQue.question]} onChange={e => onChangeInput(e, inputQue.question)} />
          </div>
        )
      )
    });
  };

  const closeTour = (e) => {
    e.stopPropagation();
    setIsTourOpen(false);
  };

  const removeEmptyArrays = (obj) => {
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key]) && (obj[key].length === 0 || (obj[key].length === 1 && obj[key][0] === ''))) {
            delete obj[key];
        }
    });
    return obj;
  };

  const moveToNextStep = (event) => {
    event.preventDefault();
    if (myContainer.current) {
      myContainer.current.scrollTo(0, 0);
    }
    if (currentStep === 1 && selectedVibes.length < 3) {
      toast.error('Please choose at least 3 Vibes', Helper.ToastOptions);
    } else if (currentStep === 2 && selectedDestinations.length < 1) {
      toast.error('Please select at least 1 destination', Helper.ToastOptions);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }

    if (!formState.name || formState.phone.length != 10 || !formState.email) {
      toast.error('Please fill the details', Helper.ToastOptions);
      if (myContainer.current) {
        myContainer.current.scrollTo(0, 10000);
      }
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email) {
      Helper.trackAnalytics(`FindYourHive`, 'Click_Find_my_hive', formState.phone);
      setSubmittingForm(true);
      const details = {...formState, 'Selected Vibes': selectedVibes, 'Selected Destination': selectedDestinations};
      delete details.name;
      delete details.email;
      delete details.phone;
      removeEmptyArrays(details);

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          details,
          type: 'findYourHive',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          if (data.status == 'success') {
            setCurrentStep(currentStep + 1); 
          }
        })
        .catch((err) => {
          setSubmittingForm(false);
          console.log(err.message);
        });
    }
  };

  const vibeImages = [
    {
      id: "Hiking is Therapy",
      src: '/fmh/1.png',
    },
    {
      id: "Soaking it all Slowly",
      src: '/fmh/2.png',
    },
    {
      id: "Express Through Art",
      src: '/fmh/3.png',
    },
    {
      id: "Story-telling Nights",
      src: '/fmh/4.png',
    },
    {
      id: "Bikes & Bros",
      src: '/fmh/5.png',
    },
    {
      id: "Connect with Inner Self",
      src: '/fmh/6.png',
    },
    {
      id: "Chasing the Thrill",
      src: '/fmh/7.png',
    },
    {
      id: "Explore Like a Local",
      src: '/fmh/8.png',
    },
    {
      id: "Where Map Dont Reach",
      src: '/fmh/9.png',
    },
    {
      id: "Flavors Lead the Way",
      src: '/fmh/10.png',
    },
    {
      id: "Travel, Wrapped in Comfort",
      src: '/fmh/11.png',
    },
    {
      id: "Sunsets & Sundowners",
      src: '/fmh/12.png',
    },
  ];

  const destinationsImages = [
    {
      id: "North Western Himalayas",
      src: '/dest/img1.png',
    },
    {
      id: "North Eastern Himalayas",
      src: '/dest/img2.png',
    },
    {
      id: "Sacred Plains",
      src: '/dest/img3.png',
    },
    {
      id: "Southern Escapes",
      src: '/dest/img4.png',
    },
    {
      id: "Desert and Royal Heartlands",
      src: '/dest/img5.png',
    },
    {
      id: "Coastal Paradises",
      src: '/dest/img6.png',
    },
    {
      id: "Himalayan Kingdoms",
      src: '/dest/img7.png',
    },
    {
      id: "Island Escapes",
      src: '/dest/img8.png',
    },
    {
      id: "Central Asia",
      src: '/dest/img9.png',
    }
  ];

  const toggleDayToDayVive = (vibeId) => {
    let currentSelectedVibes = [];
    setSelectedVibes((prevSelected) => {
      if (prevSelected.includes(vibeId)) {
        currentSelectedVibes = prevSelected.filter((id) => id !== vibeId);
      } else {
        currentSelectedVibes = [...prevSelected, vibeId];
      }

      return currentSelectedVibes;
    });
  };

  const toggleDestinations = (vibeId) => {
    let currentSelectedDestinations = [];
    setSelectedDestinations((prevSelected) => {
      if (prevSelected.includes(vibeId)) {
        currentSelectedDestinations = prevSelected.filter((id) => id !== vibeId);
      } else {
        currentSelectedDestinations = [...prevSelected, vibeId];
      }

      return currentSelectedDestinations;
    });
  };

	return (
    <div style={{overflow: 'hidden', textAlign: 'center'}}>

      <div ref={myContainer} className={styles.container} style={{bottom: (props.open ? '0' : '-6000px'), transition: (props.open ? 'all 0.3s ease' : 'all 1.5s ease')}}>
        <i className={styles.downArrow} onClick={props.toggleFindYourHive}><DownArrow fill={'#fff'} width={'40'} height={'40'} /></i>
        
        {currentStep === 1 && <div>
          <h3>Pick your Travel Vibe</h3>
          <p>Your vibe reflects how you love to travel—whether it’s chasing sunsets, exploring hidden trails, or bonding over bonfires.<br/>Choose <b>at least 3 Vibes</b> that speak to your travel soul and we’ll craft the perfect journey for you!</p>

          <div className={styles.imageGrid}>
            {vibeImages.map((image, i) => (
              <div key={image.id} style={{width: ((width - 36) / 2) - 5, height: ((width - 36) / 2) - 5}} className={`${selectedVibes.includes(image.id) ? styles.imageTileSelected : styles.imageTile}`} onClick={() => toggleDayToDayVive(image.id)}>
                
                {i === 1 && isTourOpen && <div className={styles.tourWrap}>
                  <i onClick={(e) => closeTour(e)} className={styles.closeIcon}><CloseBlack /></i>
                  <label>Click on the image to select</label>
                  <div className={styles.leftArrow}></div>
                </div>}

                <img src={image.src} alt={`Image ${image.id}`} />
                {selectedVibes.includes(image.id) && <img className={styles.checkIcon} src="/check-icon-yellow2.png" />}
              </div>
            ))}
          </div>
          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next_from_vibes', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 2 && <div>
          <h3>Where Does Your Heart Belong?</h3>
          <p>Pick destinations that excite you the most, choose at least 1 of the destinations below to get started!</p>

          <div className={styles.imageGrid}>
            {destinationsImages.map((image, i) => (
              <div key={image.id} style={{width: ((width - 36) / 2) - 5, height: ((width - 36) / 2) - 5}} className={`${selectedDestinations.includes(image.id) ? styles.imageTileSelected : styles.imageTile}`} onClick={() => toggleDestinations(image.id)}>
                <img src={image.src} alt={`Image ${image.id}`} />
                {selectedDestinations.includes(image.id) && <img className={styles.checkIcon} src="/check-icon-yellow2.png" />}
              </div>
            ))}
          </div>
          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Next_from_destinations', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>}
        </div>}

        {currentStep === 3 && <div className={styles.formContainer}>
          <h3>Let’s Personalize Your Trip</h3>
          <p>Help us understand your travel preferences better so we can craft an experience that truly feels like you.</p>

          <div>
            <form style={{marginTop: '30px', paddingBottom: '60px'}}>
              {getQuestionnaires()}

              <div>
                <label className={styles.label}>Your name... *</label>
                <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
              </div>

              <div>
                <label className={styles.label}>WhatsApp number... *</label>
                <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
              </div>

              <div>
                <label className={styles.label}>Email ID... *</label>
                <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
              </div>
            </form>
          </div>

          {props.open && <button type="button" className={styles.nextButton} onClick={(e) => { Helper.trackAnalytics('FindYourHive', 'Click_Submit', 'Submit'); submitForm(e); }}>Submit</button>}
        </div>}

        {currentStep === 4 && <div className={styles.formFilled}>
          <h3 style={{fontSize: '32px'}}>Congrats... you are all set!</h3>
          <p style={{fontSize: '20px'}}>We’ve got your vibe! Our team is matching you with the ideal travel groups and experiences. Sit tight—we’ll get back to you soon!⛰️🏖️</p>
        </div>}
        <ToastContainer />
      </div>
    </div>
  )
}

export default FindYourHive;  
