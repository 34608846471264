import React, { useEffect, useState, } from "react";
import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { Sidebar, BottomBar, LoginHelper, Helper, Blogs, ContactUs, Footer, Communities, FindYourHive, Filters, } from "../components";
import { Logo, Experiences, Explorer,  } from '../icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Oval } from  'react-loader-spinner';
import ReactPlayer from 'react-player';


const Home = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openFindYourHive, setOpenFindYourHive] = useState(false);
  const isUserLoggedIn = LoginHelper.isUserLoggedIn();
  const { height, width } = Helper.getWindowDimensions();
  const [hives, setHives] = useState([]);
  const [featuredExperiences, setFeaturedExperiences] = useState([]);
  const [categories, setCategories] = useState([]);
  let [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState('experiences');
  const [isSticky, setIsSticky] = useState(false);
  const [isTabSticky, setIsTabSticky] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const durations = ['Day Experiences', 'Multi Day Experiences'];
  const [selectedDurations, setSelectedDurations] = useState([]);

  const routeChange = (e, url) => {
      navigate(url);
  };

  const getAnalyticsCategory = () => {
    return 'Home';
  };

  const applyFilters = (cats, durs) => {
    setSelectedCategories([...cats]);
    setSelectedDurations([...durs]);
    smoothScrollToPosition(document.getElementById('scrollToPositionOnTabClick').offsetTop);
  };

  useEffect(() => {
    const handleScroll1 = () => {
      const stickyDiv = document.getElementById('tabs'); // Target sticky element
      if (!stickyDiv) return; // Prevent errors if the element doesn't exist yet

      const stickyOffset = stickyDiv.offsetTop; // Get the offset from top of the element
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop; // Scroll position

      // If scroll position exceeds the sticky div's offset, make it sticky
      if (scrollTop > stickyOffset) {
        if (!isTabSticky) setIsTabSticky(true);
      } else {
        if (isTabSticky) setIsTabSticky(false);
      }
    };

    // Attach scroll listener to document.body
    document.body.addEventListener('scroll', handleScroll1);

    // Cleanup event listener when the component is unmounted
    return () => {
      document.body.removeEventListener('scroll', handleScroll1);
    };
  }, []);

  useEffect(() => {
    const handleScroll2 = () => {
      const stickyDiv = document.getElementById('categories'); // Target sticky element
      if (!stickyDiv) return; // Prevent errors if the element doesn't exist yet

      const stickyOffset = stickyDiv.offsetTop; // Get the offset from top of the element
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop; // Scroll position

      // If scroll position exceeds the sticky div's offset, make it sticky
      if (scrollTop > stickyOffset) {
        if (!isSticky) setIsSticky(true);
      } else {
        if (isSticky) setIsSticky(false);
      }
    };

    // Attach scroll listener to document.body
    document.body.addEventListener('scroll', handleScroll2);

    // Cleanup event listener when the component is unmounted
    return () => {
      document.body.removeEventListener('scroll', handleScroll2);
    };
  }, []);

  useEffect(() => {
      fetch(`${global.config.SERVER_URL}/api/v1/groups?active=true`, {headers: {...Helper.getCommonHeaders(),}})
        .then((response) => response.json())
        .then((res) => {
          const featuredExperiences = res.data.filter(ex => ex.featured && ex.featured > 0);
          featuredExperiences.sort((a, b) => a.featured > b.featured);

          setFeaturedExperiences(featuredExperiences);
          setHives(res.data);
          const allCategories = [];
          res.data.forEach((hive) => {
              allCategories.push(hive.categories)
          });

          const uniqueCategories = allCategories.flat(1).filter((value, index, self) =>
            index === self.findIndex((t) => (
              t['_id'] === value['_id']
            ))
          );
          setCategories(uniqueCategories);
        })
        .catch((err) => {
          console.log(err.message);
        });
  }, {});

  const onGroupClick = (e, slug, url) => {
    Helper.trackAnalytics(getAnalyticsCategory(), `Click_${Helper.titleCase(slug.split('/')[slug.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate_Group`);
    routeChange(e, `/groups/${slug}`);
  };

  const onCategoryClick = (e, category) => {
    if (selectedCategories.includes(category['_id'])) {
      Helper.trackAnalytics(getAnalyticsCategory(), `Remove_category_filter`, category.label);
      selectedCategories = selectedCategories.filter(sc => sc !== category['_id']);
    } else {
      Helper.trackAnalytics(getAnalyticsCategory(), `Add_category_filter`, category.label);
      selectedCategories.push(category['_id']);
    }

    setSelectedCategories([...selectedCategories]);
    smoothScrollToPosition(document.getElementById('scrollToPositionOnTabClick').offsetTop + 45);
  };

  const getCreatorsName = (creators) => {
    let result = '';

    // Get the names of all creators
    const creatorNames = creators.map(creator => creator.name);

    // If there's more than 1 creator, join the names with commas, and put "and" before the last one
    if (creatorNames.length > 1) {
      result = creatorNames.slice(0, -1).join(', ') + ' and ' + creatorNames[creatorNames.length - 1];
    } else {
      result = creatorNames[0]; // If only one creator
    }

    return result;
  };

  function smoothScrollToPosition(targetPosition) {
    const startPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const distance = targetPosition - startPosition;
    const duration = 500; // Duration of the scroll in milliseconds
    const startTime = performance.now();

    // Animation loop using requestAnimationFrame
    function animateScroll(time) {
      const elapsed = time - startTime; // Time elapsed since animation started
      const progress = Math.min(elapsed / duration, 1); // Normalize progress (0 to 1)

      // Ease-in-out cubic easing function (smooth transition)
      const easing = progress < 0.5
        ? 4 * progress * progress * progress
        : (progress - 1) * (2 * progress - 2) * (2 * progress - 2) + 1;

      // Calculate the current scroll position based on easing
      const currentScrollPosition = startPosition + distance * easing;

      // Set the current scroll position
      document.body.scrollTop = document.documentElement.scrollTop = currentScrollPosition;

      // Continue the animation if the scroll is not yet finished
      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    }

    // Start the smooth scroll animation
    requestAnimationFrame(animateScroll);
  }

  const changeTab = (e, tab) => {
    if (tab !== selectedTab) {
      setSelectedTab(tab);
      
      smoothScrollToPosition(document.getElementById('scrollToPositionOnTabClick').offsetTop);
    }
  };

  const getFilteredHives = (hives) => {
    return hives.filter(hive => (selectedCategories.length === 0 || hive.categories.map(c => c['_id']).filter(value => selectedCategories.includes(value)).length > 0) && (selectedDurations.length === 0 || (selectedDurations.includes('Day Experiences') && hive.dayHive === true) || (selectedDurations.includes('Multi Day Experiences') && hive.dayHive === false)));
  };

  const toggleFilter = (e, isOpen) => {
    e.stopPropagation();
    setOpen(!isOpen);
    if (!isOpen) {
      setIsFilterOpen(!isOpen);  
    } else {
      setTimeout(function() {
          setIsFilterOpen(!isOpen);  
      }, 300); 
    }
  };

  const toggleFindYourHive = () => {
    Helper.trackAnalytics('FindYourHive', `Click_${openFindYourHive ? 'Close' : 'Open'}_FindYourHive`, 'Toggle FindYourHive');
    setOpenFindYourHive(!openFindYourHive);
  };

  return (
    <div className={styles.homePage}>
      {featuredExperiences.length == 0 ? <Oval
        height={40}
        width={40}
        color="#FFEA00"
        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#ccc"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : <div style={{paddingBottom: isUserLoggedIn ? '80px' : '10px'}}>

        <div className={styles.topSection}>
          <i className={styles.logo}><Logo /></i>
          
          <h1 className={styles.pageHeading}>Looking for co-travelers who vibe right?</h1>
          <p className={styles.pageSubHeading}>Struggling to find people who travel like you?<br/>Take our <span>Vibe Check</span> and gain access to <span>travel communities</span> that match your vibe.</p>
        
          <div className={styles.searchInput} onClick={toggleFindYourHive}>
            <img src="/ai-search-02-stroke-rounded 1.svg" />
            <span>Get Matched Now!</span>
          </div>
        </div>
        <FindYourHive toggleFindYourHive={toggleFindYourHive} open={openFindYourHive} />
        <Sidebar analyticsCategory={getAnalyticsCategory()} />

        <div className={styles.featuredExperiences}>
          <span className={styles.bordersTop}></span>
          <div className={styles.hives}>
            <h3 className={styles.groupsHeading}>Featured community experiences</h3>
            <p className={styles.groupsSubHeading}>Curated community travel experiences by niche travel creators  across unique destinations</p>

            <div className="featuredCarousel">
              <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={5} autoPlay={false} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false}>
                {featuredExperiences.map((hive, i) => {
                  return(<div onClick={e => onGroupClick(e, hive.slug, hive.link)} className={styles.featuredCarouselItem} key={i}>
                    <div style={{position: 'relative'}}>
                      {hive.image.includes(".mp4") ? <ReactPlayer className={styles.videoContainer1} url={hive.image} playsinline muted={true} loop={true} playing={true} width={0.8 * width} height={(0.8 * width) / 0.8} config={{file: {attributes: {crossOrigin: 'true',},},}} /> : <img width={0.8 * width} height={(0.8 * width) / 0.8} src={hive.image} alt={i} />}
                      {hive.idealFor && <label className={styles.idealFor}>{hive.idealFor}</label>}
                      {hive.duration && <label className={styles.duration}>{hive.duration}</label>}
                    </div>
                    <h3>{hive.title}</h3>
                    {hive.creators.length > 0 && <label className={styles.curator}>An experience by {getCreatorsName(hive.creators)}</label>}
                  </div>)
                })}
              </Carousel>
            </div>
          </div>
        </div>

        <div id="scrollToPositionOnTabClick"></div>
        <div className={`${styles.tabs} ${isTabSticky ? styles.stickyTabs : ''}`} id="tabs">
          <div className={selectedTab === 'experiences' ? styles.selectedTab : styles.tab} onClick={e => changeTab(e, 'experiences')}>
            <i><Experiences /></i> Experiences
          </div>
          <span></span>
          <div className={selectedTab === 'explorer' ? styles.selectedTab : styles.tab} onClick={e => changeTab(e, 'explorer')}>
            <i><Explorer /></i> Explorer’s Desk
          </div>
        </div>

        {selectedTab === 'experiences' && <div>
          {hives.length > 0 && <div className={styles.experiences}>
            <h3 className={styles.groupsHeading2}>All experiences</h3>

            {categories.length > 0 && <div className={`${styles.categories} ${isSticky ? styles.sticky : ''}`} id="categories">
              <div className={styles.filters} onClick={e => toggleFilter(e, open)}>
                <img src="/filters.svg" />
                <label className={styles.label}>Filters</label>
                <img className={styles.downArrow} src="/down-arrow.svg" />
              </div>

              {categories.map((category, i) => {
                return(
                  <div className={(selectedCategories.includes(category['_id']) ? styles.selectedCategory : styles.category)} key={i} onClick={e => onCategoryClick(e, category)}>
                    <img src={category.icon} />
                    <label className={styles.label}>{category.label}</label>
                  </div>
                );
              })}
            </div>}

            <Filters applyFilters={applyFilters} toggleFilter={toggleFilter} open={open} setIsFilterOpen={setIsFilterOpen} selectedCategories={selectedCategories} categories={categories} durations={durations} selectedDurations={selectedDurations} />

            <ul className={styles.groupsData}>
              {getFilteredHives(hives).map((hive, i) => {
                return(<li onClick={e => onGroupClick(e, hive.slug, hive.link)} className={styles.group} key={i}>
                  <div style={{position: 'relative'}}>
                    {hive.image.includes(".mp4") ? <ReactPlayer className={styles.videoContainer2} width={width - 40} height={(width - 40) * 1.33} url={hive.image} playsinline muted={true} loop={true} playing={true} config={{file: {attributes: {crossOrigin: 'true',},},}} /> : <img src={hive.image} alt={i} />}
                    {hive.idealFor && <label className={styles.idealFor}>{hive.idealFor}</label>}
                  </div>
                  <h3>{hive.title}</h3>
                  {hive.creators.length > 0 && <label className={styles.curator}>Curated by - {getCreatorsName(hive.creators)}</label>}
                  {hive.location && hive.location.city && <p className={styles.location}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M10 1.66699C13.3823 1.66699 16.25 4.52368 16.25 7.98941C16.25 11.5103 13.3357 13.9812 10.6437 15.6613C10.4476 15.7742 10.2257 15.8337 10 15.8337C9.77425 15.8337 9.55242 15.7742 9.35625 15.6613C6.66937 13.9647 3.75 11.5225 3.75 7.98941C3.75 4.52368 6.61767 1.66699 10 1.66699Z" fill="black" stroke="white" strokeWidth="1.5"/>
                      <path d="M15.0007 15C16.0379 15.3537 16.6673 15.8183 16.6673 16.3271C16.6673 17.4351 13.6826 18.3333 10.0007 18.3333C6.31875 18.3333 3.33398 17.4351 3.33398 16.3271C3.33398 15.8183 3.96338 15.3537 5.00065 15" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
                      <path d="M12.5 7.91699C12.5 9.29774 11.3807 10.417 10 10.417C8.61925 10.417 7.5 9.29774 7.5 7.91699C7.5 6.53628 8.61925 5.41699 10 5.41699C11.3807 5.41699 12.5 6.53628 12.5 7.91699Z" stroke="white" strokeWidth="1.5"/>
                    </svg>
                    <span className={styles.city}>{hive.location.city}</span>
                  </p>}
                  <p className={styles.description}>{hive.description}</p>
                </li>)
              })}
              {getFilteredHives(hives).length === 0 && <li className={styles.noResults}>No Results, Please adjust your filters.</li>}
            </ul>
          </div>}
        </div>}

        {selectedTab === 'explorer' && <div className={styles.explorerSection}>
          <Communities heading={'Communities for you'} subHeading={'Get inspired with updates on your favorite areas and interests'} analyticsCategory={getAnalyticsCategory()} headingStyle={{margin: '16px 0 0 12px', fontFamily: 'var(--font-poppins)', fontWeight: 500, border: 'none'}} subHeadingStyle={{margin: '0 12px 8px 12px', fontSize: '12px', fontWeight: 400, }} communitiesCarouselStyle={{paddingLeft: '12px'}} />
          <div className={styles.blogs}><Blogs analyticsCategory={getAnalyticsCategory()} heading={'Bits of Wanderlust'} subHeading={'Quick reads to fuel your travel inspiration'} containerStyle={{marginTop: '12px', paddingLeft: '12px'}} /></div>
          <ContactUs bgColor={'#fff'} style={{padding: '0 12px 20px'}} />
        </div>}

        <Footer />
        {isUserLoggedIn && <BottomBar analyticsCategory={'BottomBar_Home'} />}
      </div>}
    </div>
  );
};

export default Home;
